<template>
  <div>
    <Inquiry />
  </div>
</template>

<script>
import Inquiry from "../../components/Inquiry/Inquiry.vue";
import { confirmBox } from "../../utils/confirmBox";

export default {
  components: { Inquiry },
  beforeRouteUpdate(to, from, next){
    confirmBox(this.$store.state.routeLeaveAlert, next);
  },
  beforeRouteLeave(to, from, next) {
    confirmBox(this.$store.state.routeLeaveAlert, next);
  },
};
</script>
