<template>
  <popup-window
    :active="active"
    :hasClose="true"
    noMargin
    width="60vw"
    maxWidth="60vw"
    height="80vh"
    maxHeight="80vh"
    containerStyle="overflow-y: auto;"
    @popup-close="requestClose"
    @popup-back="requestClose"
  >
    <div style="padding: 0px 20px 20px 20px; height: 100%">
      <div class="geo-title-selected">Selected geo tagged images</div>
      <div class="geo-images-container">
        <div
          v-for="image in selected"
          :key="'selected-' + image.id"
          class="geo-image"
        >
          <img
            :src="image.url"
            alt="Geo Gallery Image"
            class="centered-image"
          />
          <div
            v-if="selectedOptions && selectedOptions.length && !image.loading"
          >
            <div class="geo-more-vert-button" @click="moreVertClick(image.id)">
              <v-icon class="geo-icon">more_vert</v-icon>
            </div>
            <div
              v-if="moreVertOpenedOn === image.id"
              :id="`geo-action-container-${image.id}`"
              class="geo-actions-container"
            >
              <div
                v-for="option in selectedOptions"
                :key="`geo-action-${option.text}`"
                class="geo-action"
                :style="`color: ${option.color || 'black'};`"
                @click.prevent="
                  () => {
                    moreVertClick(image.id);
                    option.onClick(image);
                  }
                "
              >
                {{ option.text }}
              </div>
            </div>
          </div>
          <div v-else-if="image.loading" class="geo-upload-container">
            <div class="geo-upload-button" style="cursor: unset">
              <v-progress-circular
                indeterminate
                color="white"
                size="20"
                width="1"
              ></v-progress-circular>
            </div>
          </div>
        </div>
        <div
          v-if="!selected || !selected.length"
          class="empty-images-container"
        >
          No images selected
        </div>
      </div>
      <div class="geo-title-gallery">Geo tagged gallery</div>
      <div class="geo-images-container">
        <div
          v-for="image in geoImages"
          :key="'geo-' + image.id"
          class="geo-image"
        >
          <img
            :src="image.url"
            alt="Geo Gallery Image"
            class="centered-image"
          />
          <div class="geo-upload-container">
            <div
              v-if="!image.loading"
              class="geo-upload-button"
              @click.prevent="onSelectGeoImage(image)"
            >
              <v-icon class="geo-icon">upload</v-icon>
            </div>
            <div v-else class="geo-upload-button" style="cursor: unset">
              <v-progress-circular
                indeterminate
                color="white"
                size="20"
                width="1"
              ></v-progress-circular>
            </div>
          </div>
        </div>
        <div v-if="!geoImages.length" class="empty-images-container">
          No images to show. <br>Please add geo tagged images from seamless source mobile app.
        </div>
      </div>
    </div>
  </popup-window>
</template>

<script>
export default {
  name: "GeoSelector",
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Array,
      default: () => [],
    },
    geoImages: {
      type: Array,
      default: () => [],
    },
    selectedOptions: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    moreVertOpenedOn: null,
  }),
  methods: {
    requestClose() {
      this.$emit("close");
    },
    onSelectGeoImage(image) {
      this.$emit("select", image);
    },
    moreVertClick(id) {
      if (this.moreVertOpenedOn === id) {
        this.moreVertOpenedOn = null;
        return;
      }
      this.moreVertOpenedOn = id;
    },
  },
};
</script>

<style lang="scss">
.geo-images-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  gap: 20px;
}
.geo-image {
  position: relative;
  background-color: transparent;
  width: calc((100% - 80px) / 5);
  aspect-ratio: 1/1;
  border: 1px solid $primaryDarkest;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.centered-image {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}
.geo-upload-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 7px;
}
.geo-upload-button {
  padding: 10px;
  border-radius: 50%;
  background-color: #473068;
  border: 2px solid white;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.geo-more-vert-button {
  @extend .geo-upload-button;
  padding: 2px;
  border: 1px solid white;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 5px;
  right: 5px;
}
.geo-icon {
  color: white !important;
}
.geo-title-selected {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 5px;
  text-align: left;
  color: $primaryDark;
}
.geo-title-gallery {
  @extend .geo-title-selected;
  margin-top: 20px;
}
.geo-actions-container {
  width: 120px;
  height: auto;
  z-index: 8;
  position: absolute;
  top: 30px;
  right: 5px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 2.96703px 13.8462px 11.8681px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
.geo-action {
  cursor: pointer;
  text-align: left;
  font-size: 14px;
  margin: 5px;
}
.empty-images-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100%;
  font-size: 16px;
  color: $primaryDark;
  background-color: $backgroundColor;
}
</style>
