<template>
    <div>
        <div class="close" @click="closeWindow">
            <popup-close 
                style="float: right;" 
            />
        </div>
        <div v-if="loading" class="text-center" style="height: 500px;">
                <md-progress-spinner class="color_stroke" md-mode="indeterminate"></md-progress-spinner>
        </div>
        <div v-else class="care-instruction-container">
            <h1 style="font-family: 'TrebuchetMS',sans-serif; color:#492a67">Care Instructions</h1>
            <div>
                <v-data-table
                    class="text-color"
                    :headers="headers"
                    :items="careInstructions"
                    :search="search"
                    :loading="loadingTable"                
                    :no-data-text="noDataText"
                    :hide-default-footer="true"
                    :items-per-page="itemsPerPage"
                    :page.sync="page"
                    :scrollable="false"
                    @pagination="paginated"
                    style="color:#492a67"
                    
                >
                    <template v-slot:[`item.id`]="{ item }">
                        <td class="tdata-noborder">{{careInstructions.indexOf(item)+1}}</td>
                    </template>

                    <template v-slot:[`item.icon`]="{ item }">
                        <td class="tdata-noborder">
                            <img v-if="item.icon" class="care-instruction-img" :src="item.icon"/>
                            <div v-else style="color: rgba(0, 0, 0, 0.38); margin: 41.5px 0px;">No data Available</div>
                        </td>
                    </template>

                    <template v-slot:[`item.care_instruction_name`]="{ item }">
                            <td class="tdata-noborder">
                                <span v-if="item.care_instruction_name">{{item.care_instruction_name}}</span>
                                <span v-else style="color: rgba(0, 0, 0, 0.38);">No data Available</span>
                            </td>
                    </template>
                    
                    <template v-slot:top>
                        <v-toolbar
                            flat
                        ><v-text-field
                            class="search-field"
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            hide-details
                            style="width:5px !important; color:#492a67;"
                        >
                        </v-text-field>
                            <v-spacer></v-spacer>
                            <v-dialog
                                v-model="addDialog"
                                max-width="500px"
                                persistent
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        class="mb-2 new-care-instruction-btn"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        New Care Instruction
                                    </v-btn>
                                </template>
                                <v-card>
                                    <v-card-title>
                                        <span class="text-h4">Add New Care Instruction</span>
                                    </v-card-title>
                                    <v-card-text>
                                        <v-container>
                                            <v-row>
                                                <v-col
                                                    cols="12"
                                                >
                                                    <v-file-input
                                                        accept="image/png, image/jpeg, image/bmp"
                                                        small-chips
                                                        label="Care Instruction Icon"
                                                        v-model="addingItem.icon"
                                                        clearable
                                                    ></v-file-input>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                >
                                                    <v-text-field
                                                        v-model="addingItem.care_instruction_name"
                                                        label="Care Instruction Name"
                                                        maxlength="250"
                                                        clearable
                                                        style="color:#492a67"
                                                    ></v-text-field>
                                                    <div 
                                                        v-if="
                                                            instructionError && 
                                                            !addingItem.icon && 
                                                            !addingItem.care_instruction_name"
                                                        style="color:red;"
                                                    >
                                                        Icon or Care instruction name is required
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="purple darken-1"
                                            text
                                            @click="close('add')"
                                        >
                                            Cancel
                                        </v-btn>
                                        <v-btn
                                            color="purple darken-1"
                                            text
                                            @click="addNewCareInstruction"
                                        >
                                            Save
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                            <v-dialog
                                v-model="editDialog"
                                max-width="500px"
                                persistent
                            >
                                <v-card>
                                    <v-card-title>
                                        <span class="text-h4">Edit Care Instruction</span>
                                    </v-card-title>
                                    <v-card-text>
                                        <v-container>
                                            <v-row>
                                                <v-col
                                                    cols="12"
                                                >
                                                    <div style="display:inline-block">
                                                        <div style="width:110px; margin-bottom:10px;">
                                                            <div style="text-align:right;">
                                                                <v-button style="cursor:pointer;" v-if="updateIcon"><v-icon @click="removeEditIcon">close</v-icon></v-button>
                                                            </div>
                                                            <img class="care-instruction-img" v-if="updateIcon" :src="updateIcon"/>
                                                            <span class="care-instruction-img" v-else style="color: rgba(0, 0, 0, 0.38);">No icon provided</span>
                                                        </div>
                                                    </div>
                                                    <v-file-input
                                                        accept="image/png, image/jpeg, image/bmp"
                                                        label="Care Instruction Icon"
                                                        v-model="editedItem.icon"
                                                        @change="previewUpdatedImg"
                                                        :clearable="false"
                                                        style="color:#492a67"
                                                    ></v-file-input>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                >
                                                    <v-text-field
                                                        v-model="editedItem.care_instruction_name"
                                                        label="Care Instruction Name"
                                                        maxlength="250"
                                                        clearable
                                                        id="certification-name-field" 
                                                    ></v-text-field>
                                                    <div 
                                                        v-if="
                                                            instructionError && 
                                                            !editedItem.icon && 
                                                            !editedItem.care_instruction_name" 
                                                        style="color:red;"
                                                    >
                                                        Icon or care instruction name is required
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="purple darken-1"
                                            text
                                            @click="close('edit')"
                                        >
                                            Cancel
                                        </v-btn>
                                        <v-btn
                                            color="purple darken-1"
                                            text
                                            @click="editCareInstruction"
                                        >
                                            Save
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                            <v-dialog v-model="dialogDelete" max-width="500px">
                                <v-card>
                                    <v-card-title class="text-h5">Are you sure you want to delete this care instructions ?</v-card-title>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="purple darken-1" text @click="closeDelete">Cancel</v-btn>
                                        <v-btn color="purple darken-1" text @click="deleteItemConfirm">OK</v-btn>
                                        <v-spacer></v-spacer>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-toolbar>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <div class="icon-cont">
                            <div class="action-button" @click="editItem(item)">
                                <edit-inquiry width="15" height="16"/>
                            </div>
                            <div class="action-button" @click="deleteItem(item)" width="15" height="16">
                                <delete-icon width="15" height="16"/>
                            </div>
                        </div>
                    </template>
                </v-data-table>
            </div>
            <div style="position: relative; min-height: 70px; height: 100%; width: 100%;">
                <div style="position: absolute; bottom: 0; right: 0;">
                    <pagination
                        :currentPage="page"
                        :totalCount="itemCount"
                        @load-page="loadPage"
                        :perPageCount="itemsPerPage"
                        v-if="itemCount"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import PopupClose from "../../assets/icons/PopupClose.vue"
import restAdapter from "../../restAdapter";
import notification from '../../notification';
import Pagination from '../Pagination.vue';

export default {
    name:"NonConsumerCareInstructionHandleForm",
    components: {
        PopupClose,
        Pagination,
    },
    props:{
        showCareInstructions:{
            type:Boolean,
        }
    },
    data: ()=>({
        page: 1,
        itemCount:0,
        itemsPerPage: 5,
        search:'',
        searchingcareInstructions : false,
        loading: true,
        loadingTable: false,
        editDialog: false,
        addDialog: false,
        dialogDelete: false,
        noDataText:' Please wait...',
        headers: [
            { text: 'Number', value: 'id', align: 'start',  sortable: false ,filterable:false, width: '20%' },
            { text: 'Care Instruction Icon', value: 'icon', align: 'start', sortable: false, width: '30%' },
            { text: 'Care Instruction Name', value: 'care_instruction_name', align: 'start', sortable: false, width: '30%' },
            { text: 'Actions', value: 'actions', align: 'center', sortable: false ,width: '20%' },
        ],
        careInstructions:[],
        editedIndex: -1,
        editItemId: null,
        editedItem: {},
        addingItem:{},
        defaultItem: {},
        deleteItemId: null,
        updateIcon:null,
        instructionError:false,
        editIconRemoved:false,
    }),

    watch: {
        showCareInstructions: {
            handler: function(){
                if(this.showCareInstructions){
                    this.initialize();
                }
            } 
        }
    },

    mounted(){
        this.initialize ();
    },

    methods: {
      loadPage(page){
        this.page = page;
      },
      paginated(pagination){
        this.itemCount = pagination.itemsLength;
      },
      closeWindow(){
        this.$emit('close')
      },
      initialize () {
        restAdapter
          .get(`/api/trace/care_instructions/all_care_instructions`)
                .then(response => {
                    this.loading = false;
                    this.careInstructions = response.data.careInstructions.filter(item=>item.is_showing);
                    if(!this.careInstructions.length){
                        this.noDataText = 'No care instructions found'
                    }
            }).catch(error => {
                    console.log(error);
            });
      },

      addNewCareInstruction () {
        this.instructionError =  false;
        if(!this.addingItem.icon && !this.addingItem.care_instruction_name){
            this.instructionError =  true;
            return
        }
        this.loadingTable = true;
        const formData = new FormData();
        this.addingItem.icon ? formData.append('icon',this.addingItem.icon) : null;
        this.addingItem.care_instruction_name && formData.append('name',this.addingItem.care_instruction_name);
        restAdapter.post(`/api/trace/care_instruction/add`, formData)
          .then(response => {
                this.loadingTable = false;
                if(!response.data.already_added_status){
                    this.careInstructions.push({...response.data, icon: response.data.icon ? URL.createObjectURL(this.addingItem.icon) : null });
                    this.$emit('update-instructions', this.careInstructions);
                    notification.success("New Care Instructions Added Successfully");
                }
                else{
                    notification.error("This Care Instruction Name is Already Added");
                }
                this.addingItem = {};
          })
          .catch(error => {
                notification.error(error.response.data.error);
                this.loadingTable = false;
                this.addingItem = {};
          });
      },

      editItem (item) {
        this.updateIcon = item.icon;
        this.editIconRemoved = false;
        this.editedIndex = this.careInstructions.indexOf(item);
        this.editItemId = item.id;
        this.editedItem = Object.assign({}, item);
        this.editDialog = true;
      },

      editCareInstruction(){
        if(!this.editedItem.icon && !this.editedItem.care_instruction_name){
            this.instructionError =  true;
            return
        }
        this.loadingTable = true;
        const formData = new FormData();
        this.editedItem.icon ? formData.append('icon',this.editedItem.icon) : null;
        formData.append('name',this.editedItem.care_instruction_name);
        formData.append('icon_remove_status',this.editIconRemoved);
        restAdapter.post(`/api/trace/care_instruction/update/${this.editItemId}`,formData)
          .then(response => {
            this.loadingTable = false;
            this.careInstructions = this.careInstructions.map(careInstruction =>{
                if(careInstruction.id === this.editItemId){
                    return {...careInstruction, 
                            icon: typeof this.editedItem.icon === 'string' || this.editedItem.icon instanceof String 
                                ? careInstruction.icon 
                                : !this.editedItem.icon ? null : URL.createObjectURL(this.editedItem.icon), 
                            care_instruction_name: this.editedItem.care_instruction_name
                            }
                    }
                return {...careInstruction}
            })
            this.editDialog = false;
            this.$emit('update-instructions', this.careInstructions);
            notification.success("Care Instruction Updated successfully");
          })
          .catch(error => {
              notification.error(error.response.data.message);
              this.loadingTable = false;
          });
        },

        close (status) {
            this.instructionError = false;
            if(status === 'add'){
                this.addDialog = false;
                this.editedIndex = -1;
            }
            else{
                this.editDialog = false;
                this.editedIndex = -1;
            }
        },
        closeDelete () {
            this.dialogDelete = false;
            this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem);
            this.editedIndex = -1;
            })
        },

        deleteItem (item) {
            this.editedIndex = this.careInstructions.indexOf(item);
            this.deleteItemId = item.id;
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;       
        },

        deleteItemConfirm () {
            this.careInstructions.splice(this.editedIndex, 1);
            restAdapter.post(`/api/trace/care_instruction/remove/${this.deleteItemId}`)
            .then(response => {
                this.$emit('update-instructions', this.careInstructions);
                notification.success("Care Instruction deleted successfully");
            })
            .catch(error => {
                notification.error(error.response.data.error);
            });
            this.closeDelete() 
        },

        previewUpdatedImg(){
            if(this.editedItem.icon) {
                this.updateIcon = URL.createObjectURL(this.editedItem.icon);
                this.editIconRemoved = false;
            }
            else{
                this.updateIcon = this.updateIcon;
                this.editIconRemoved = false;
            }
        },

        removeEditIcon(){
            this.updateIcon = null;
            this.editedItem.icon = null;
            this.editIconRemoved = true;
        },
    },
}
</script>

<style scoped>
.care-instruction-container{
    padding: 0px 30px 30px 30px;
    left: 370px;
    top: 353px;
    background-color: #FFFFFF;
    border-radius: 7px;
    margin: auto;
}
th{
    color:#492a67;
}
.color_stroke::v-deep .md-progress-spinner-circle{
    stroke: #492a67 !important;
}

.search {
    padding: 2px 5px 2px 5px; 
    margin-right: 5px;
    text-align: right;
    width: 289px;
}

.new-care-instruction-btn{
    background-color: #492a67 !important;
    color: white !important;
    border-color:  #492a67 !important;
    border-style: solid;
}

.care-instruction-img{
    width: 80px;
    height: 80px;
    margin: 12px 0px;
}
.close-icon{
    float: right;
    border-radius: 50%;
    box-shadow: 0 0 2px #333;
    cursor: pointer;
    height: 1.5rem;
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    width: 2.5rem;
    font-size: 28px;
    color:#492a67;

}
/* .close-icon:hover {
    background-color: #f70b0b;
} */

.v-data-table td {
    padding: 0px 0px;
}

.v-toolbar__content, .v-toolbar__extension {
    padding: 0px 0px;
}

.tdata-noborder{
    border-right : 0px solid !important;
    border-left : 0px solid !important;
}
.text-center1 {
    /* margin: 10px 0px; */
    border-radius: 3px;
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}
.page{
    border-radius: 3px;
}
label{
    color:#492a67;
}
.v-data-table-header{
    color:#492a67;
}
.text-start{
    color:#492a67;
}
.text-color > .v-data-table__wrapper > table > thead > tr:last-child > th{
    color:#492a67;
}
.theme--light.text-color > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row){
    border-color: #492a67;
}
.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
    border-color: #492a67;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
    border-color: #492a67;
}
.v-icon.v-icon.v-icon--link {
    color: #492a67;
}
.search-field .v-icon.v-icon {
    color:#492a67;
}
.icon-cont {
    display: flex;
    justify-content:center;
}
.action-button {
    cursor: pointer;
    margin: 8px;
}
.close {
    margin: 0px 18px 18px 18px;
    padding-top: 18px;
    width: auto;
    cursor: pointer;
  }
</style>
