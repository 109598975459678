<template>
  <div class="main-container">
    <popup-window
      :active="showRenameFileForm"
      :hasClose="false"
      noMargin
      @popup-close="resetForms"
      @popup-back="resetForms"
    >
      <div class="rename-file-form">
        <div class="rename-file-title">Rename file:</div>
        <input
          v-model="newFileName"
          class="rename-file-input"
          type="text"
          placeholder="Type New Name"
          id="search"
          name="search"
        />
        <div class="rename-file-buttons">
          <ui-button
            class="primary short space"
            @click="renameFile(file)"
            title="✔ Ok"
          />
          <ui-button class="lighter short" @click="resetForms" title="Cancel" />
        </div>
      </div>
    </popup-window>

    <div class="row update-row" :style="isEditable ? 'margin-top: -45px;' : ''">
      <div class="col-md-12 col-12 d-flex flex-column mb-2 mb-md-0">
        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 5px;
            font-weight: bold;
            font-size: 14pt;
          "
        >
          <div class="files-title">Files</div>
          <div v-if="isEditable" class="switch-container">
            <v-switch
              :input-value="subTaskApproveStatus"
              @click="subtaskApproval(subTaskId)"
              color="#442D65"
              class="approve-switch"
            ></v-switch>
            <span class="switch-label">Approve</span>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  class="more-vert-icon alert-icon"
                  v-bind="attrs"
                  v-on="on"
                  style="margin-left: 5px"
                >
                  mdi-alert-circle-outline
                </v-icon>
              </template>
              <span>Approve / Disapprove sub task</span>
            </v-tooltip>
          </div>
        </div>
        <div style="color: #473068; text-align: left">
          Last modified: {{ latestFileDate }}
        </div>
      </div>
    </div>

    <div class="row fixed-container">
      <div class="col-md-4">
        <v-select
          @input="sortSubTaskContent(selectedItem)"
          class="selection-field"
          label="Sort by"
          item-value="type"
          color="#442D65"
          :items="sortItems"
          v-model="selectedItem"
          outlined
        >
          <template v-slot:item="{ item }">
            <span>{{ item.type }}</span>
          </template>
          <template v-slot:selection="{ item }">
            <span>{{ item.type }}</span>
          </template>
        </v-select>
      </div>
      <div v-if="isEditable" class="col-md-4">
        <v-select
          @input="sortSubTaskContent(selectedItem)"
          class="selection-field"
          label="Filter by"
          item-value="value"
          color="#442D65"
          :items="filterItems"
          v-model="selectedFilter"
          outlined
        >
          <template v-slot:item="{ item }">
            <span>{{ item.label }}</span>
          </template>
          <template v-slot:selection="{ item }">
            <span>{{ item.label }}</span>
          </template>
        </v-select>
      </div>
      <div v-if="isEditable" class="col-md-4">
        <v-select
          @input="filteredFiles"
          class="selection-field"
          label="Uploaded by"
          item-text="name"
          item-value="id"
          color="#442D65"
          :items="companies"
          v-model="selectedCompanies"
          multiple
          outlined
        >
        </v-select>
      </div>
    </div>

    <div v-if="subtaskLoading" class="text-center loading-container">
      <md-progress-spinner
        class="color_stroke subtask-loader"
        md-mode="indeterminate"
      ></md-progress-spinner>
    </div>
    <div class="file-scroll-container">
      <v-row
        v-if="subTaskData.length && selectedItem != 'Type'"
        class="file-container"
        no-gutters
      >
        <v-col
          no-gutters
          v-for="file in filteredFiles"
          :key="file.id"
          cols="12"
          md="3"
          class="file-col"
        >
          <div class="file-data-container">
            <div
              class="file-image"
              :style="`background-image: url(${
                isImage(file)
                  ? `${baseUrl}/api/inquiries/file_show/${file.upload_name}`
                  : imageFile
              });`"
              @click="
                handlePreview(
                  `${baseUrl}/api/inquiries/file_show/${file.upload_name}`
                )
              "
            >
              <div class="more-vert-container">
                <div
                  class="more-vert clickable"
                  @click="options(file.id, $event)"
                >
                  <v-icon class="more-vert-icon">more_vert</v-icon>
                </div>
                <div
                  v-if="
                    authUserOrganizationId ==
                    file.user.account.organization_registry_id
                  "
                  @click="updateFileVisibility(file)"
                  class="more-vert clickable"
                >
                  <v-tooltip color="#473068" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon class="more-vert-icon" v-bind="attrs" v-on="on">
                        {{
                          file.is_private_in_style_file
                            ? "mdi-eye-lock"
                            : "mdi-eye-lock-open-outline"
                        }}
                      </v-icon>
                    </template>
                    <div class="hover-container">
                      <span>
                        {{
                          file.is_private_in_style_file
                            ? "Click here to share between all the companies added to this style."
                            : "Click here to make private to your company."
                        }}
                      </span>
                    </div>
                  </v-tooltip>
                </div>
              </div>
              <div v-if="actionId === file.id" class="actions-container">
                <div class="actions-list" @click="downloadFile(file, $event)">
                  Download
                </div>
                <div
                  v-if="
                    isEditable &&
                    authUserOrganizationId ==
                      file.user.account.organization_registry_id
                  "
                  class="actions-list"
                  @click="renamePopUp(file)"
                >
                  Rename
                </div>
                <div
                  v-if="
                    isEditable &&
                    authUserOrganizationId ==
                      file.user.account.organization_registry_id
                  "
                  class="actions-list"
                  @click="deleteFile(file, $event)"
                >
                  Delete
                </div>
              </div>
            </div>
            <div class="file-size">
              {{
                file.file_size > 1000
                  ? (file.file_size / 1024).toFixed(2) + " Mb"
                  : file.file_size + " Kb"
              }}
            </div>
            <div class="file-info-container">
              <div class="file-name-date">
                <truncate-hovering
                  :text="fileTitle(file.title)"
                  :length="11"
                  :appendText="'.' + fileExtension(file.title)"
                  :displayAppendOnHover="true"
                />
                <div class="file-date">
                  {{ new Date(file.created_at).toLocaleString() }}
                </div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row
        v-if="subTaskData.length && selectedItem == 'Type'"
        class="file-container"
      >
        <v-row class="file-type" v-for="fileType in arrType" :key="fileType.id">
          <v-col cols="12"
            >{{ fileType }}
            <hr
          /></v-col>
          <v-col
            v-for="file in filteredFiles.filter(
              (data) => fileType == data.mime_type
            )"
            :key="file.id"
            cols="12"
            md="3"
            class="file-col text-left"
          >
            <div class="file-data-container">
              <div
                class="file-image"
                :style="
                  isImage(file)
                    ? `background-image: url(${baseUrl}/api/inquiries/file_show/${file.upload_name});`
                    : `background-image: url(${imageFile});`
                "
              >
                <div class="more-vert-container">
                  <div @click="options(file.id)" class="more-vert clickable">
                    <v-icon class="more-vert-icon">more_vert</v-icon>
                  </div>
                  <div
                    v-if="
                      isEditable &&
                      authUserOrganizationId ==
                        file.user.account.organization_registry_id
                    "
                    @click="updateFileVisibility(file)"
                    class="more-vert clickable"
                  >
                    <v-tooltip color="#473068" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="more-vert-icon"
                          v-bind="attrs"
                          v-on="on"
                          >{{
                            file.is_private_in_style_file
                              ? "mdi-eye-lock"
                              : "mdi-eye-lock-open-outline"
                          }}</v-icon
                        >
                      </template>
                      <div class="hover-container">
                        <span>{{
                          file.is_private_in_style_file
                            ? "Click here to share between all the companies added to this style."
                            : "Click here to make private to your company."
                        }}</span>
                      </div>
                    </v-tooltip>
                  </div>
                </div>
                <div v-if="actionId == file.id" class="actions-container">
                  <div class="actions-list" @click="downloadFile(file)">
                    Download
                  </div>
                  <div
                    v-if="
                      isEditable &&
                      authUserOrganizationId ==
                        file.user.account.organization_registry_id
                    "
                    class="actions-list"
                    @click="renamePopUp(file)"
                  >
                    Rename
                  </div>
                  <div
                    v-if="
                      isEditable &&
                      authUserOrganizationId ==
                        file.user.account.organization_registry_id
                    "
                    class="actions-list"
                    @click="deleteFile(file)"
                  >
                    Delete
                  </div>
                </div>
              </div>
              <div class="file-info-container">
                <div class="file-size">
                  {{
                    file.file_size > 1000
                      ? (file.file_size / 1024).toFixed(2) + " Mb"
                      : file.file_size + " Kb"
                  }}
                </div>
                <div class="file-name-date">
                  <truncate-hovering
                    :text="fileTitle(file.title)"
                    :length="20"
                    :appendText="'.' + fileExtension(file.title)"
                    :displayAppendOnHover="true"
                  />
                  <div class="file-date">
                    {{ new Date(file.created_at).toLocaleString() }}
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-row>
    </div>

    <div v-if="totalFileSizeError" style="color: red; text-align: left">
      {{ fileSummationErrorMessage }}
    </div>
    <div v-if="fileSizeError" style="color: red; text-align: left">
      {{ maxFileSizeErrorMessage }}
    </div>
    <vue-dropzone
      v-if="isEditable"
      class="dropzone mb-3"
      ref="myVueDropzone"
      id="dropzone"
      :options="dropzoneOptions"
      @vdropzone-file-added="addFiles"
      @vdropzone-removed-file="removeFiles"
      @vdropzone-error="collectErrors"
    ></vue-dropzone>
    <div style="text-align: center">
      <span v-if="uploadFiles.length">
        {{ uploadFiles.length }}
        <span v-if="uploadFiles.length == 1"> file </span>
        <span v-else> files </span>
        <span> selected </span>
      </span>
    </div>
    <div v-if="showProgress" class="file-uploading-indicator text-center">
      <label>Uploading {{ progress }}%</label>
      <md-progress-bar
        class="color_stroke"
        md-mode="determinate"
        :md-value="progress"
      ></md-progress-bar>
    </div>
    <v-row v-if="isEditable">
      <v-col cols="12" style="text-align: right">
        <ui-button
          class="mt-3 mb-3 mr-0"
          style="margin-right: 30px !important"
          :className="
            !uploadFiles.length ||
            unsupportedFiles.length ||
            saveBtnDisable ||
            warnMessage
              ? 'disable'
              : 'primary'
          "
          :disabled="
            !uploadFiles.length ||
            unsupportedFiles.length != 0 ||
            saveBtnDisable ||
            warnMessage
          "
          @click="save"
        >
          Upload
        </ui-button>
      </v-col>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/camelcase */
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import notification from "../../notification";
import * as conf from "../../conf.yml";
import restAdapter from "../../restAdapter";
import { isImageFile } from "../../utils/media-type";
import auth from "../../auth";

export default {
  name: "StyleFilesContainer",
  components: {
    vueDropzone: vue2Dropzone,
  },

  data: () => ({
    saveBtnDisable: false,
    baseUrl: conf.default.server.host,
    sortItems: [
      { id: 1, type: "Name" },
      { id: 2, type: "Date" },
      { id: 3, type: "Type" },
      { id: 4, type: "Size" },
    ],
    filterItems: [
      { label: "All", value: "all" },
      { label: "Shared", value: "shared" },
      { label: "Private", value: "private" },
    ],
    selectedFilter: "all",
    selectedCompanies: [],
    selectedItem: "",
    dropzoneOptions: {
      url: "https://",
      autoProcessQueue: false,
      thumbnailWidth: 200, // px
      thumbnailHeight: 200,
      maxFilesize: 50,
      language: {
        dictDefaultMessage:
          "<div style='filter: grayscale(1)'>📤</div> Drag and Drop (or) Choose File(s)",
      },
      acceptedFiles:
        ".pdf,.jpg,.jpeg,.png,.svg,.gif,.ai,.eps,.ait,.svgz,.bmp,.css,.dwg,.dxf,.emf,.swf,.pct,.psd,.tif,.tga,.txt,.wmf,.xlsx,.xlsm,.xlsb,.xltx,.xltm,.xls,.xlt,.xls,.xml,.xlam,.xlw,.xlr,.xla,.mp4,.mp3,.tuka,.gerber,.tiff,.ng,.pxr,.dcm,.pbm,.jps,.dng,.raw,.ct,.tga,.ait,.epsf,.ps,.doc,.docm,.docx,.dot,.dotx,.pptx,.pptm,.ppt,.potx,.potm,.pot,.ppsx,.pps,.ppsm,.cdr,.wav,.mov,.dmg,.aae,.heic,.dotm,.dotx,.dot,.htm,.html,.mht,.mhtml,.odt,.rtf,.wps,.dic,.csv,.dbf,.dif,.ods,.prn,.slk,.xps,.ppa,.ppam,.thmx,.wmv,.abr,.csh,.psb,.u3d,.8ba,.8bam,.8bbr,.8bc,.8bco,.8bct,.8bdt,.8be,.8bem,.8bf,.8bfk,.8bfm,.8bi,.8bi8,.8bim,.8bl,.8bp,.8bpf,.8bpi,.8bs,.8bx,.8bxm,.8bym,.8li,.8ly,.acb,.acf,.acm,.aco,.act,.acv,.ado,.ahs,.ahu,.ai3,.ai8,.alv,.amp,.ams,.asl,.asp,.asr,.ast,.asv,.atf,.atn,.ava,.avs,.axt,.blw,.cha,.cpsd,.csf,.dcm,.dcs2,.dic,.drx,.eap,.edb,.enf,.eps2,.eps3,.ensi,.ensp,.erf,.ffo,.fl3,.gbt,.grd,.hdr,.hdt,.hss,.iros,.irs,.isa,.inf,.imbs,.mag,.mdo,.mos,.mpo,.mtl,.p3e,.p3l,.p3m,.p3r,.pam,.pat,.pdd,.pf,.pipl,.pipeg,.pmb,.pmg,.prst,.psdt,.psf,.pxr,.g2g,.g5r,.rfr,.shc,.ssh,.spiff,.sta,.swb,.tbo,.thr,.tpl,.ype,._v,.3ds,.arw,.cr2,.crw,.csh,.dcr,.dib,.emz,.ico,.kmz,.mrw,.nef,.obj,,orf,,pcd,,plugin,.psdx,.raf,.syg,.wdp,.webp,.acbl,.afx,.ai4,.ai5,.ai6,.ai7,.ase,.asef,.bm2,.cal,.clp,.colz,.dae,.dc2,.dcp,.dcpr,.dcs,.dicm,.dxt5,.exif,.exr,.fff,.fif,.gif~c200,.hdr,.icb,.icc,.icm,.ifi,.iif,.jp2,.jp2,.jpg-large,.jpg-original,.ipx,.ixr,.kdc,.kdk,.kodak,.kys,.lcpp,.lex,.mnu,.mxp,.nrw,.pat,.pbm,.pcq,.pdp,.pef,.png-large,.pnm,.pop,.psid,.q7q,.rwl,.sct,.sdpx,.spf,.srw,.stn=,.tdi,.vph,.wbm,.x3f,.zdct,.aac,.aiff,.m2a,.m4a,.mp2,.h264,.3gp,.3gpp,.avc,.mpeg-2,.mpeg-4,.mts,.mxt,.r3d,.ts,.vob,.dpx,.mov,.jpeg2000,.mpo,.kmz,.jps,.dae,.pcx,.taaga,.svq,.svqz,.aia,.aip,.ait,.aia,.aip,.ait,.cef,.cqm,.clx,.epf,.epsp,.fit,.fmod,.iros,.irs,.kys,.psid,.pwl,.sbx,.pic,.kys,.psid,.pwl,.sbx,.art,.env,.fh10,.fh11,.fh7,.fh8,.fh9,.fh8,.fxq,.idea,.j2k,.jp2,.jpf,.jpx,.lex,.pxr,.vda,.vst,.zdct",

      addRemoveLinks: true,
    },
    subTaskListData: [],
    uploadFiles: [],
    unsupportedFiles: [],
    fileSummationErrorMessage:
      "Selected files are exceeding the maximum file summation limit (100mb)",
    maxFileSizeErrorMessage: "One or more files are too large to upload (50mb)",
    totalFileSizeError: false,
    fileSizeError: false,
    totalFileSize: 102000000,
    maximumFileSize: 52000000,
    subtaskLoading: false,
    actionId: null,
    arraySizeType: [],
    imageFile: require(`../../assets/folder.png`),
    fileType: null,
    arrType: [],
    showRenameFileForm: false,
    newFileName: "",
    file: null,
    showProgress: false,
    progress: 0,
  }),

  props: {
    inquiryId: {
      type: Number,
      required: true,
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
    subTaskData: {
      type: Array,
      default: () => [],
    },
    subTaskId: {
      type: Number,
      required: true,
    },
    warnMessage: {
      type: Boolean,
      default: false,
    },
    companies: {
      type: Array,
      default: () => [],
    },
    subTaskApproveStatus: {
      type: [Boolean, Number],
      default: false,
    },
  },

  watch: {
    subTaskId: {
      handler: function (value) {
        this.selectedItem = "";
      },
      deep: true,
    },
  },

  filters: {
    truncate: function (text, stop, clamp) {
      return text.slice(0, stop) + (stop < text.length ? clamp || "..." : "");
    },
  },

  computed: {
    authUserOrganizationId() {
      return auth.user.account.organization_registry_id;
    },
    filteredFiles() {
      switch (this.selectedFilter) {
        case "shared":
          return this.filterFilesBySupplier(
            this.subTaskData.filter((file) => !file.is_private_in_style_file)
          );
        case "private":
          return this.filterFilesBySupplier(
            this.subTaskData.filter((file) => file.is_private_in_style_file)
          );
        default:
          return this.filterFilesBySupplier(this.subTaskData);
      }
    },
    latestFileDate() {
      if (!this.subTaskData.length)
        return this.$moment().format("MMMM Do YYYY hh:mma");
      const latestFile = this.subTaskData.reduce((latest, file) => {
        const fileDate = new Date(file.created_at);
        return fileDate > latest ? fileDate : latest;
      }, new Date(0));

      return this.$moment(latestFile).format("MMMM Do YYYY hh:mma");
    },
  },

  methods: {
    sortSubTaskContent(selectedItem) {
      switch (selectedItem) {
        case "Date":
          return this.subTaskData.sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at)
          );
        case "Name":
          return this.subTaskData.sort((a, b) => {
            const fname = a.title.toLowerCase(),
              lname = b.title.toLowerCase();
            if (fname < lname) {
              return -1;
            }
            if (fname > lname) {
              return 1;
            }
            return 0;
          });
        case "Type":
          this.arrType = [];
          this.subTaskData.sort((a, b) => {
            const type1 = a.mime_type.toLowerCase(),
              type2 = b.mime_type.toLowerCase();
            if (type1 < type2) {
              return -1;
            }
            if (type1 > type2) {
              return 1;
            }
            return 0;
          });

          for (let i = 0; i < this.subTaskData.length; i++) {
            this.arrType.push(this.subTaskData[i].mime_type);
          }
          this.arrType = [...new Set(this.arrType)]; //remove duplicates
          break;
        case "Size":
          return this.subTaskData.sort((a, b) => a.file_size - b.file_size);
      }
    },
    filterFilesBySupplier(files) {
      if (this.selectedCompanies.length) {
        return files.filter((file) =>
          this.selectedCompanies.includes(
            file.user.account.organization_registry_id
          )
        );
      }
      return files;
    },

    options(id) {
      this.actionId = this.actionId ? null : id;
    },

    subtaskApproval(id) {
      this.$emit("subtask-approval", id);
    },

    updateFileVisibility(file) {
      restAdapter
        .post(
          `/api/inquiries/${this.inquiryId}/update_visibility_in_style_file/${file.upload_name}`,
          {
            make_private: !file.is_private_in_style_file,
            style_file_id: this.subTaskId,
          }
        )
        .then(() => {
          if (!file.is_private_in_style_file) {
            notification.success(
              "File visibility updated to 'private' successfully"
            );
          } else {
            notification.success(
              "File visibility updated to 'shared' successfully"
            );
          }
          this.$emit("update-file-visibility", {
            subTaskId: this.subTaskId,
            fileUniqueId: file.upload_name,
            isPrivate: !file.is_private_in_style_file,
          });
          this.$emit("refresh-activity-log", 1);
        });
    },

    downloadFile(file) {
      window.open(
        `${conf.server.host}/api/inquiries/file_download/${file.upload_name}`
      );
    },

    deleteFile(file) {
      const payload = {
        id: file.id,
      };
      if (confirm("Delete this file?")) {
        restAdapter
          .delete(
            `/api/inquiries/${this.inquiryId}/style_file_delete/${file.upload_name}`,
            payload
          )
          .then(() => {
            notification.success("File deleted successfully");
            this.$emit("reload-data", 1);
            this.$emit("refresh-activity-log", 1);
          });
      }
    },
    resetForms() {
      this.showRenameFileForm = false;
      this.newFileName = "";
    },

    renamePopUp(file) {
      this.showRenameFileForm = true;
      this.file = file;
    },
    renameFile(file) {
      if (!this.newFileName) {
        return;
      }
      restAdapter
        .put(`/api/inquiries/${this.inquiryId}/file/${file.upload_name}/edit`, {
          name: this.newFileName,
          style_file_id: this.subTaskId,
        })
        .then(() => {
          notification.success("File renamed successfully");
          this.resetForms();
          this.showRenameFileForm = false;
          this.actionId = null;
          this.$emit("reload-data", 1);
          this.$emit("refresh-activity-log", 1);
        });
    },

    isImage(file) {
      return isImageFile(file);
    },

    fileTitle(title) {
      return title.split(".")[0];
    },

    fileExtension(title) {
      return title.split(".").pop();
    },

    addFiles(file) {
      if (file.size > this.maximumFileSize) {
        this.fileSizeError = true;
      }
      this.uploadFiles.push({ file });

      let fileSize = 0;
      for (let index = 0; index < this.uploadFiles.length; index++) {
        fileSize = fileSize + this.uploadFiles[index].file.size;
      }
      this.totalFileSizeError = fileSize > this.totalFileSize ? true : false;
    },

    removeFiles(file) {
      let fileSize = 0;

      for (let index = 0; index < this.uploadFiles.length; index++) {
        if (this.uploadFiles[index].file.name === file.name) {
          this.uploadFiles.splice(index, 1);
        }
        fileSize =
          fileSize +
          (this.uploadFiles[index] ? this.uploadFiles[index].file.size : 0);
      }

      for (let index = 0; index < this.unsupportedFiles.length; index++) {
        if (this.unsupportedFiles[index].file.name === file.name) {
          this.unsupportedFiles.splice(index, 1);
        }
      }
      this.totalFileSizeError = fileSize > this.totalFileSize ? true : false;
      this.fileSizeError = this.unsupportedFiles.length > 0 ? true : false;
    },

    collectErrors(file) {
      this.unsupportedFiles.push({ file });
    },

    save() {
      this.saveBtnDisable = true;
      this.showProgress = true;
      if (this.uploadFiles.length !== 0) {
        const formData = new FormData();
        for (let i = 0; i < this.uploadFiles.length; i++) {
          const file = this.uploadFiles[i].file;
          formData.append("file[" + i + "]", file);
        }
        restAdapter
          .post(
            `/api/inquiries/${this.inquiryId}/style_files/${this.subTaskId}/style_file_upload`,
            formData,
            {
              onUploadProgress: (progressEvent) => {
                this.progress = Math.floor(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
              },
            }
          )
          .then(() => {
            this.showProgress = false;
            notification.success("File uploaded successfully");
            this.$refs.myVueDropzone.removeAllFiles();
            this.saveBtnDisable = false;
            this.progress = 0;
            this.$emit("reload-data", 1);
            this.$emit("refresh-activity-log", 1);
          })
          .catch((error) => {
            this.showProgress = false;
            this.progress = 0;
            this.saveBtnDisable = false;
            notification.error(error.response.data);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.attachments-label {
  text-align: left;
  color: #442d65;
  font-weight: 600;
}

.image-container-col {
  justify-content: center;
  padding: 5px !important;
  flex-grow: 0;
  margin-top: 10px;
}

.alert-icon {
  transition: transform 0.2s;
}

.alert-icon:hover {
  transform: scale(1.2);
}

.image-container {
  position: static;
  width: 75px;
  height: 75px;
  background-size: 100%;
  border: 0.5px solid $primaryDark;
  border-radius: 5px;
  filter: drop-shadow(0px 4px 4px rgba(72, 49, 105, 0.15));
}

.more-vert {
  background: $paginateText;
  width: 16px;
  height: 16px;
  float: right;
  border-radius: 50%;
  cursor: pointer;
}

.more-vert:hover {
  border: 0.5px solid $primaryDark;
  filter: drop-shadow(0px 4px 4px rgba(72, 49, 105, 0.15));
  z-index: 10;
}

.more-vert-enhanced {
  position: absolute;
  right: 0;
  top: 0;
}

.non-preview-container {
  width: 75px;
  height: 75px;
  border: 0.5px solid $primaryDark;
  border-radius: 5px;
  position: relative;
  filter: drop-shadow(0px 4px 4px rgba(72, 49, 105, 0.15));
}

.non-preview-content-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  font-size: 10px;
  font-weight: bold;
  padding: 1px;
  opacity: 0.7;
}
.v-application {
  line-height: 1.3;
}
.non-preview-file-title {
  overflow: hidden;
  white-space: nowrap;
  background-color: #fff;
}
.pinned-content-container {
  text-align: left;
  margin: 5px;
  overflow: hidden;
}

.actions-container {
  position: relative;
  right: 0;
  top: 20px;
  width: 100px;
  height: auto;
  overflow: visible;
  z-index: 10;
  background: rgba(255, 255, 255, 0.85);
  box-shadow: 2.96703px 13.8462px 11.8681px rgba(0, 0, 0, 0.1);
}

.actions-list {
  overflow: visible;
  color: $infoMessage;
  cursor: pointer;
}
.actions-list:hover {
  color: $tableBorder;
  background: rgba(255, 255, 255, 0.95);
}

.date-show {
  font-size: 10px;
}

.dropzone::v-deep {
  margin-top: 10px;
  margin-right: 30px;
  background-color: #ffffff;
  border: 0.5px dashed #442d65;
  color: $tableBorder;
  height: 150px;
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
  overflow: auto;
  align-content: flex-start;

  .dz-preview {
    height: 155px;
  }

  .dz-preview .dz-progress {
    opacity: 0 !important;
  }
  .dz-error-message {
    display: none !important;
  }
  .dz-preview .dz-details {
    background-color: rgb(198, 168, 236, 0.7) !important;
  }
  .dz-preview .dz-remove {
    color: $secondaryDarkest !important;
  }

  .dz-preview .dz-image {
    width: 145px;
    height: 100%;
  }
}

.main-container {
  height: 100%;
  display: block;
  flex-direction: column;
}

.update-row {
  width: 100%;
  margin-bottom: -20px;
}

.file-details-activator {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.hover-container {
  text-align: left;
}

.fixed-container {
  width: 100%;
  padding-top: 5px;
  position: relative;
}

.fixed-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 2.5%;
  width: 95%;
  border-top: 1px solid #ccc;
}

.fixed-container::v-deep
  .v-text-field--outlined
  > .v-input__control
  > .v-input__slot {
  background-color: white;
}

.file-scroll-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  margin-top: 10px;
}

.image-data-container {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
}

.image-size {
  height: 100px;
  background-size: cover;
  background-position: center;
  border-radius: 5px;
}

.actions-container,
.more-vert-container,
.file-name,
.file-date {
  margin-top: 10px;
}

.file-uploading-indicator {
  margin-top: 10px;
}

.approve-switch {
  top: 20px;
  right: 50px;
  margin-top: 0px;
}

.switch-container {
  display: flex;
  margin-top: 20px;
  margin-bottom: -15px;
  justify-content: flex-end;
  align-items: center;
}

.files-title {
  font-size: 20px;
  font-weight: bolder;
  color: #442d65;
  margin-bottom: -10px;
}

.switch-label {
  color: #473068;
  margin-left: 5px;
  font-size: 12px;
  font-weight: 200;
}

.approve-switch::v-deep .v-messages {
  display: none;
}
.clickable {
  cursor: pointer;
}

.disable {
  pointer-events: none;
  opacity: 0.6;
}

.primary {
  background-color: #442d65;
  color: white;
}

.lighter {
  background-color: #ccc;
  color: black;
}

.ui-button {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  margin-right: 10px;
}

.ui-button.primary {
  background-color: #442d65;
  color: white;
}

.ui-button.lighter {
  background-color: #ccc;
  color: black;
}

.ui-button.short {
  width: 80px;
}

.file-name {
  color: #442d65;
  font-size: 11px;
  padding: 0px 15px;
  margin-bottom: 7px;
  word-break: break-all;
}

.file-date {
  color: #442d65;
  font-size: 11px;
  font-weight: 400;
  margin-bottom: 3px;
  word-break: break-all;
}

.file-container {
  width: 100%;
  max-height: 300px;
  display: flex;
  justify-content: left;
  align-items: left;
  border-radius: 5px;
  margin-bottom: 10px;
  flex-wrap: wrap;
}

.file-col {
  display: flex;
  justify-content: flex-start;
}

.file-data-container {
  position: relative;
  width: 90%;
  height: 160px;
  background-color: #fff;
  border: 1px solid #ddd;
  overflow: hidden;
  padding: 10px;
  margin-bottom: 15px;
}

.theme--light.v-application {
  background-color: #ffffff;
}

.theme--light.v-list {
  background: #ffffff;
}
.v-list-item__content {
  color: white;
}
.theme--light.v-list-item:hover:before {
  opacity: 0.14;
}

.file-image {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 90px;
}

.file-type {
  width: 100%;
  margin: 0px 0px 20px 0px;
}

.image-data-container {
  border: 0.5px solid #442d64;
  border-radius: 4px;
  padding: 5px;
  box-shadow: 0px 6px 11px rgba(187, 187, 219, 0.19);
  height: 100%;
  // height: 198px;
  // align-items: center;
  // display: grid;
}

.image-size {
  position: relative;
  height: 95px;
  margin-bottom: 10px;
  width: 100%;
  // height: 145px;
  background-size: cover;
  border-bottom: 0.5px solid #442d64;
  border-radius: 4px;
  padding: 5px;
  filter: drop-shadow(0px 4px 4px rgba(72, 49, 105, 0.15));
}

.more-vert-container {
  height: 30px;
  margin-top: -10px;
  margin-left: -10px;
  width: calc(100% + 20px);
  background: #ffffff;
  align-items: center;
  justify-content: space-between;
  display: flex;
  flex-direction: row-reverse;
}

.more-vert {
  background: $paginateText;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.more-vert:hover {
  filter: drop-shadow(0px 4px 4px rgba(72, 49, 105, 0.15));
}

.more-vert-icon {
  font-size: 24px;
  vertical-align: unset;
  color: #473068;
}

.hover-container {
  max-width: 200px;
}

.actions-container {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  height: auto;
  overflow: visible;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border: 1px solid #ddd;
  z-index: 10;
  display: flex;
  background: rgba(255, 255, 255, 0.85);
  box-shadow: 2.96703px 13.8462px 11.8681px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  flex-direction: column;
}

.actions-list {
  padding: 8px;
  overflow: visible;
  color: $infoMessage;
  cursor: pointer;
  padding: 6px 10px;
}

.actions-list:hover {
  color: $tableBorder;
  background: rgba(255, 255, 255, 0.95);
}

.selection-field::v-deep .v-select__selections {
  color: #442d65;
}

.theme--light.v-list {
  background-color: #eef1fa;
}

.loading-container {
  height: 500px;
}

.color_stroke::v-deep .md-progress-spinner-circle {
  stroke: #492a67 !important;
}

.subtask-loader::v-deep .md-progress-spinner-draw {
  width: 35px !important;
}

.file-info-container {
  padding: 8px;
  display: block;
}

.file-size {
  background-color: #fff;
  bottom: 0;
  position: relative;
  right: 0;
  padding: 4px;
  margin-bottom: -10px;
  text-align: right;
  font-size: 11px;
  font-weight: bold;
}

.file-name-date {
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 5px;
  display: block;
  font-size: 10pt;
  font-weight: bold;
}
.md-progress-bar.md-theme-default.md-determinate::v-deep .md-progress-bar-fill {
  background-color: #492a67 !important;
}

.rename-file-buttons {
  padding: 30px 0 15px 0;
}
.rename-file-form {
  width: 70%;
  margin: auto;
}
.rename-file-title {
  text-align: left;
  color: $primaryDark;
  font-weight: 600;
  font-size: 16px;
  padding: 30px 0 15px 0;
}

.rename-file-input[type="text"],
select {
  height: 40px;
  width: 100%;
  border: none;
  border-bottom: 1.5px solid $primaryDark;
  color: $spinnerStroke;
}

input:focus {
  outline: none;
}
</style>
